import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import supabase from "../config/supabase";
import "../styles/navBar.css";
import { DeveloperMode } from "@/config/DeveloperMode";

export default function NavBar() {
  const normalStyle = {
    color: "white",
    textDecoration: "none",
    position: "relative",
    // left: "-40px",

    // backgroundColor: "black",
  };

  const nav = useNavigate();
  const [uuid, setUuid] = useState(null);

  const checkIfUserLoggedIn = async () => {
    const { data } = await supabase.auth.getSession();
    if (data.session === null) {
      setDisplayLogin({ display: "block" });
      setDisplaySignup({ display: "block" });
      setDisplayMyProfile({ display: "none" });
      setDisplayLogout({ display: "none" });
      setDisplayAdminPanel({ display: "none" });
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
      setDisplayLogin({ display: "none" });
      setDisplaySignup({ display: "none" });
      setDisplayMyProfile({ display: "block" });
      setDisplayLogout({ display: "block" });
      setUuid(data.session.user.id);
    }
  };
  useEffect(() => {
    checkIfUserLoggedIn();
  }, []);

  useEffect(() => {
    if (uuid) {
      getUserData(uuid);
      checkIfUserIsAdmin();
    }
  }, [uuid]);

  const logout = async () => {
    try {
      const { error: e } = await supabase.auth.signOut();
      if (e) console.log(e);
      checkIfUserLoggedIn();
      localStorage.clear();
      window.location.href = "/";
    } catch (error) {
      if (error) console.log("Error logging out:", error.message);
    }
  };

  const getUserData = async (uuid) => {
    const { data, error } = await supabase
      .from("users")
      .select()
      .eq("id", uuid);
    if (error) {
      console.log("error", error);
      return;
    }
    if (data === null) {
      nav("/form");
    } else {
      // LS.setUserData(data[0]);
    }
  };

  const [displaySignup, setDisplaySignup] = useState({ display: "none" });
  const [displayLogin, setDisplayLogin] = useState({ display: "none" });
  const [displayMyProfile, setDisplayMyProfile] = useState({ display: "none" });
  const [displayLogout, setDisplayLogout] = useState({ display: "none" });
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  //---Admin panel-----------------------------------
  const [displayAdminPanel, setDisplayAdminPanel] = useState({
    display: "none",
  });
  const checkIfUserIsAdmin = async () => {
    const { data } = await supabase.from("admin").select("id").eq("id", uuid);
    if (data.length > 0) {
      setDisplayAdminPanel({ display: "block" });
    } else {
      setDisplayAdminPanel({ display: "none" });
    }
  };

  return (
    <Navbar collapseOnSelect expand='lg' variant='dark' className='navbar-font'>
      <Container>
        <Navbar.Brand>
          <Link to='/' style={normalStyle}>
            Church Camp 2025
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto'>
            <Nav.Link as={Link} to='/about'>
              About
            </Nav.Link>
            <Nav.Link as={Link} to='/pricing'>
              Pricing
            </Nav.Link>
            {isLoggedIn && (
              <Nav.Link as={Link} to='/campers'>
                Campers
              </Nav.Link>
            )}
            <Nav.Link as={Link} to='/admin' style={displayAdminPanel}>
              Dashboard
            </Nav.Link>
            <NavDropdown title='More' id='collasible-nav-dropdown'>
              {/* <NavDropdown.Item href='#action/3.2'>
                Schedule (coming soon)
              </NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to='/contact'>
                Contact
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to='/terms-of-service'>
                Terms of Service
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href='https://www.cbmbc.org/' target='_blank'>
                CBMBC page
              </NavDropdown.Item>
              <NavDropdown.Item
                href='https://youtube.com/playlist?list=PLK1Ko106Om3pXtfal0eLMJcH0o-ZOnQKE'
                target='_blank'
              >
                YouTube playlist 2022
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <Nav.Link as={Link} to={"/signup"} style={displaySignup}>
              Sign up
            </Nav.Link>
            <Nav.Link as={Link} to={"/login"} style={displayLogin}>
              Log in
            </Nav.Link>
            <Nav.Link as={Link} to={"/profile"} style={displayMyProfile}>
              My Profile
            </Nav.Link>
            <Nav.Link
              onClick={logout}
              style={displayLogout}
              className='nav-login-btn'
            >
              Log Out
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
