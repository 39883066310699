import { React, useEffect, useState } from "react";
import "../styles/home.css";
import "../styles/transition.css";
import "../styles/text.css";
import cbmbc from "../images/logos/cbmbc.jpg";
import mbmbc from "../images/logos/mbmbc.png";
import bbmbm from "../images/logos/bbmbm.jpeg";
import nbmbm from "../images/logos/nbmbm.jpg";
import supabase from "../config/supabase";
import { useInView, InView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import campLogo from "../images/logos/camp_logo_2024.svg";
import CountUp from "react-countup";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import VideoPlayer, { VideoBackground } from "@components/home/VideoPlayer.js";
import Section from "@components/Section.js";

export default function Home() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  // Effect to handle window resize
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width and height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [totalUsers, setTotalUsers] = useState(0);

  const [fetch, setFetch] = useState();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const [displayButtonClass, setDisplayButtonClass] = useState(null);

  const navigation = useNavigate();

  //animation________________________________________________________
  const [sponsorInView, setSponsorInView] = useState(false);
  const { ref: magicSectionRef, inView: magicSectionIsVisible } = useInView({
    /* Optional options */
    threshold: 0.8,
    triggerOnce: true,
  });

  const { ref: cbmbcRef, inView: cbmbcInView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const { ref: mbmbcRef, inView: mbmbcInView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const { ref: bbmbmRef, inView: bbmbmInView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const { ref: nbmbmRef, inView: nbmbmInView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true); // Set loading to true when starting the fetch
      // const { data, error } = await supabase
      //   .from("users")
      //   .select()
      //   .eq("is_registered", true);
      const { count, error } = await supabase
        .from("users")
        .select("id", { count: "exact" }) // Request the count
        .eq("is_registered", true);
      if (error) {
        console.log(error);
        setFetch("Could not fetch users");
        setUsers("");
      }
      if (count) {
        setFetch("Fetching users");
        setTotalUsers(count);
      }
      setIsLoading(false);
    };
    fetchUsers();
    fetchUser();
  }, []);

  //Send user to /form if they are not in the database
  const fetchUser = async () => {
    const { data, error } = await supabase.auth.getSession();
    if (data.session !== null) {
      setDisplayButtonClass({ display: "none" });
      setIsUserLoggedIn(false);
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user != null) {
        const { data } = await supabase
          .from("users")
          .select()
          .eq("id", user.id);
        if (data.length === 0) {
          navigation("/form");
        } else {
        }
      } else if (error) {
        console.log("Error: " + error);
      } else {
      }
    } else {
      setIsUserLoggedIn(true);
    }
  };

  const [errorLoading, setErrorLoading] = useState("Loading...");
  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorLoading("Could not load");
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className='full-screen-section'>
        <VideoBackground url='https://youtu.be/3GrrUfiOcq0' />
        {/* <div className='camp-background'></div> */}
        <div className='hero-div' ref={magicSectionRef}>
          <div className='hero-contents'>
            <h1
              className={`${
                magicSectionIsVisible
                  ? "title-camp show-fadeIn"
                  : "title-camp hidden-fadeIn"
              }`}
            >
              {" "}
              Church Family Camp
            </h1>
            <Image
              src={campLogo}
              alt='camp logo'
              className={`${
                magicSectionIsVisible ? "show-fadeIn" : "hidden-fadeIn"
              }`}
              width={`${windowSize.width <= 850 ? "200" : "300"}`}
            />
            <p
              className={`${
                magicSectionIsVisible
                  ? "subtitle show-fadeIn"
                  : "subtitle hidden-fadeIn"
              }`}
            >
              May 28 - June 1, 2025
            </p>
            <div className='register-count-div'>
              <p
                className={`${
                  magicSectionIsVisible ? "show-fadeIn" : "hidden-fadeIn"
                }`}
              >
                Total registered
              </p>
              {isLoading ? (
                <p
                  className={`${
                    magicSectionIsVisible ? "show-fadeIn" : "hidden-fadeIn"
                  }`}
                >
                  loading...
                </p>
              ) : (
                <CountUp
                  end={totalUsers}
                  className={` counter-center ${
                    magicSectionIsVisible ? "show-fadeIn" : "hidden-fadeIn"
                  }`}
                />
              )}
            </div>
            {isUserLoggedIn ? (
              <div
                className={`button-container  ${
                  magicSectionIsVisible ? "show-fadeIn" : " hidden-fadeIn"
                }`}
                style={displayButtonClass}
              >
                <Button
                  label='Sign up now'
                  id='button-register'
                  onClick={() => navigation("/signup")}
                />
                <Button
                  label='Login'
                  id='button-login'
                  onClick={() => navigation("/login")}
                />
              </div>
            ) : (
              <div className='button-container '>
                <Button
                  className='prime'
                  label='My profile'
                  id='button-about'
                  onClick={() => navigation("/profile")}
                />
                <Button
                  className='prime'
                  label='Pay now'
                  id='button-contact'
                  onClick={() => navigation("/payment")}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/*------------------Sponsor--------------- */}
      <div className='resize-screen-section black-background'>
        <Section title='Our Mission'>
          <p>
            We want to bring everyone closer, both to each other and to God.
            We'll have great music, fun games, and lots of chances to make new
            friends and share good times. We hope to help everyone feel more
            connected and strong in their faith. Come join us for a special time
            of friendship and growing in faith together.
          </p>
          <Button
            className='prime'
            label='Learn more'
            onClick={() => navigation("/about")}
          />
        </Section>
        <VideoPlayer url='https://www.youtube.com/watch?v=vORWdkioQa0&t=1s&pp=ygUFY2JtYmM%3D' />
        <Section
          title='Location'
          subtitle='Birkedal lejren'
          color='white'
          background='none'
        >
          <a
            style={{ padding: "0" }}
            className='a-remove center-grid text-center'
            href='https://www.google.com/maps/place/Birkedal+Lejren/@55.6234002,11.5894872,15z/data=!4m6!3m5!1s0x465280582d047fb7:0xfdafbe15b71c1814!8m2!3d55.6226222!4d11.5899066!16s%2Fg%2F11cjkvwhdx?entry=ttu'
            target='_blank'
          >
            <p>Overdrevsvej 10, 4340 Tølløse, Denmark</p>
            <Image
              src='https://upload.wikimedia.org/wikipedia/commons/b/bd/Google_Maps_Logo_2020.svg'
              alt='Image'
              width='150'
              x
            />
          </a>
        </Section>
        <InView
          as='div'
          onChange={(inView, entry) => {
            setSponsorInView(inView);
          }}
          triggerOnce={true}
        >
          <h1
            className={`${
              sponsorInView
                ? "title-center show-fadeIn"
                : "title-center hidden-fadeIn"
            }`}
          >
            Sponsors
          </h1>
        </InView>
        <div className='display-flex-responsive '>
          <div
            ref={cbmbcRef}
            className={`sponsor-logo-text ${
              cbmbcInView ? "show-fadeIn" : "hidden-fadeIn"
            }`}
          >
            <a
              className='logo-link'
              target='_blank'
              href={"https://www.cbmbc.org/"}
            >
              <img src={cbmbc} className={` sponsor-logo-circle`} />
              <p>CBMBC</p>
            </a>
          </div>

          <div
            ref={mbmbcRef}
            className={`sponsor-logo-text ${
              mbmbcInView ? "show-fadeIn" : "hidden-fadeIn"
            }`}
          >
            <a
              className='logo-link'
              target='_blank'
              href={"https://www.facebook.com/malmobaptists"}
            >
              <img src={mbmbc} className={`sponsor-logo-circle`} />
              <p>MBMBC</p>
            </a>
          </div>

          <div
            ref={bbmbmRef}
            className={`sponsor-logo-text ${
              bbmbmInView ? "show-fadeIn" : "hidden-fadeIn"
            }`}
          >
            <a
              className='logo-link'
              target='_blank'
              href={
                "https://barcelonabethelmissionarybaptistchurch.wordpress.com/"
              }
            >
              <img src={bbmbm} className={`sponsor-logo-circle`} />
              <p>BBMBM</p>
            </a>
          </div>

          <div
            ref={nbmbmRef}
            className={`sponsor-logo-text ${
              nbmbmInView ? "show-fadeIn" : "hidden-fadeIn"
            }`}
          >
            <a
              className='logo-link'
              target='_blank'
              href={"https://www.facebook.com/profile.php?id=100087000476991"}
            >
              <img src={nbmbm} className={`sponsor-logo-circle`} />
              <p>NBMBM</p>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
