import React from "react";

export default function TermsOfService() {
  return (
    <>
      <h1 className='text-h1'>Terms Of Service</h1>
      <div className='center text-left box-width box-white text-black'>
        <h2>1. Registration and Accommodation</h2>
        <p>
          The event operates on a first-come, first-served basis. Full
          registration guarantees a room and bed for accommodation. Should the
          event reach full capacity or payment not be completed in time, an
          alternative accommodation option will be provided. This involves
          participants bringing their own tent or sleeping bag, with a
          corresponding reduction in the registration fee.
        </p>
        <h2>2. Pricing and VAT</h2>
        <p>
          This event is hosted by a non-profit organization so our prices are
          fixed and are not subject to VAT. As the camp is organized by our
          church and missions with all personnel being volunteers, VAT charges
          do not apply.
        </p>
        <h2>3. Confirmation of Registration</h2>
        <p>
          Upon full payment, registrants will receive an email confirmation of
          their registration. This serves as your official confirmation and
          proof of booking.
        </p>
        <h2>4. Registration Deadline</h2>
        <p>
          All participants are encouraged to register by April 1, 2025. This
          deadline helps us in planning and ensuring that we have all
          essentials, such as food and T-shirts, ready in time for the camp.
          Registrations post-deadline are accepted, but we cannot guarantee the
          availability of your preferred T-shirt size. Additionally, late
          registrants may need to make their own sleeping arrangements.
        </p>
        <h2>5. Refund and Withdrawal Policy</h2>
        <p>
          We maintain a strict no-refund policy. Once a purchase has been made
          through our system, we do not accept any withdrawals or refunds. This
          policy is in place to ensure clarity and consistency in our
          transactions, facilitating a smooth registration process for all
          participants.
        </p>
        <h2>6. Use of Funds</h2>
        <p>
          As a non-profit event, all funds collected are allocated directly to
          covering essential event expenses such as food, transportation, and
          materials. No funds are distributed to individuals but are instead
          reinvested into the event.
        </p>
        <h2>7. Camp Rules</h2>
        <p>
          To ensure a safe, respectful, and spiritually enriching environment
          for all participants, we kindly request adherence to the following
          rules:
        </p>
        <ul>
          <li>
            <strong>No Smoking:</strong> Smoking is strictly prohibited
            throughout the camp to maintain a healthy and clean environment for
            everyone.
          </li>
          <li>
            <strong>No Alcohol or Illegal Substances:</strong> The consumption
            or possession of alcohol and any illegal substances is forbidden to
            ensure the safety and well-being of all participants.
          </li>
          <li>
            <strong>Attendance at Activities:</strong> All camp participants are
            expected to attend the scheduled activities. Exceptions are made
            only for illness or other serious reasons.
          </li>
          <li>
            <strong>Christian Values:</strong> Our camp is grounded in Christian
            values. As such, we ask that individuals of the opposite gender who
            are not married or directly related by family do not share
            accommodations.
          </li>
        </ul>
        <p>
          By respecting these rules, you contribute to a positive and meaningful
          camp experience for everyone involved. Thank you for your cooperation
          and commitment to upholding the values and standards of our church
          camp.
        </p>
      </div>
    </>
  );
}
